import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Multimedia Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/multimedia/"
    metaDescription="Uizard's repository of multimedia templates enable you to create designs that feel professional, sleek, and simple in no time. Explore online now."
    description="
    h2:Perfect for whenever you design projects with audio, videos, or images
    <br/>
    Drag-and-drop multimedia component template to design apps and websites that look professional and sleek.
    <br/>
    h3:Create designs that feel professional, sleek, and simple
    <br/>
    With Uizard at your disposal, you can effortlessly incorporate multimedia components into your app and website designs. Uizard's repository of multimedia templates enable you to create designs that feel professional, sleek, and simple in no time. 
    <br/>
    h3:Produce engaging interfaces even if you're not a designer
    <br/>
    Uizard is so easy to use that even if you're not a designer, you can produce engaging interfaces leveraging multimedia component template in minutes.
    <br/>
    Choose from a variety of multimedia elements to drag-and-drop to take your projects to the next level!
    "
    pages={[
      "Small video player",
      "Video gallery",
      "Video list",
      "Song list",
      "Audio player folder",
      "Audio list",
      "Audio entry",
      "Upload content",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing multimedia projects"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing multimedia projects"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing multimedia projects"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing multimedia projects"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing multimedia projects"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/component-templates/multimedia/multimedia_1.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/component-templates/multimedia/multimedia_2.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/component-templates/multimedia/multimedia_3.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/component-templates/multimedia/multimedia_4.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/component-templates/multimedia/multimedia_5.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
